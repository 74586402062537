export default {
    encode(activeDirectionIds, filters, range) {
        let filterIds = [];
        if (filters) {
            for (let i = 0; i < filters.length; i++) {
                filterIds = [...filterIds, ...filters[i].productIds];
            }
        }
        const hexFilter = this.getHexString(filterIds);
        const hexString = this.getHexString(activeDirectionIds);
        const encodeDirectionIds = Buffer.from(hexString, 'hex').toString('base64');
        const encodeFilterIds = Buffer.from(hexFilter, 'hex').toString('base64');
        if (!range) {
            range = '';
        }
        const encode = {
            directionsId: encodeURIComponent(encodeDirectionIds),
            filtersId: encodeURIComponent(encodeFilterIds),
            range: range.length ? range.join('-') : ''
        };
        return encode;
    },
    decode(directionsId, filtersId, rangeSave) {
        const directionsHex = Buffer.from(directionsId, 'base64').toString('hex');
        const decodeDirectionGuids = this.setHexItems(directionsHex.match(/([\d\w]){32}/g));
        let decodeFilterGuids;
        if (filtersId.length) {
            const filtersHex = Buffer.from(filtersId, 'base64').toString('hex');
            this.decodeFilterGuids = this.setHexItems(filtersHex.match(/([\d\w]){32}/g));
        } else {
            this.decodeFilterGuids = [];
        }
        const decode = {
            directionGuids: decodeDirectionGuids,
            filterGuids: this.decodeFilterGuids ? this.decodeFilterGuids : [],
            range: rangeSave ? rangeSave.split('-') : []
        }
        return decode;
    },
    getHexString(guidIds) {
        let hexItems = [];
        for (let i = 0; i < guidIds.length; i++) {
            hexItems = hexItems.length ? [...hexItems, guidIds[i].replace(/-/g, '')] : [guidIds[i].replace(/-/g, '')];
        }
        return hexItems.join('');
    },
    setHexItems(hexItems) {
        let guidItems = [];
        if (hexItems) {
            for (let i = 0; i < hexItems.length; i++) {
                guidItems = guidItems.length ? [...guidItems, hexItems[i].replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w+)/g, '$1-$2-$3-$4-$5')] : [hexItems[i].replace(/(\w{8})(\w{4})(\w{4})(\w{4})(\w+)/g, '$1-$2-$3-$4-$5')];
            }
        }
        return guidItems;
    }
}
